import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { FullScreenSpiner } from '@use-gateway/components';
import { useAuth, staticPropsFactory } from '@use-gateway/utils';

export const getServerSideProps = staticPropsFactory({
  serverSideTranslations,
});

export default function Home() {
  const { push } = useRouter();
  const { onHasNoAuth, onHasAuth } = useAuth();

  onHasNoAuth(() => push('/login'));
  onHasAuth(() => push('/wallet'));

  return <FullScreenSpiner />;
}
